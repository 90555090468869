import { Box, Text, HStack, VStack, Button, Icon, Image, Grid, GridItem, Input } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom'
import { useConnectWallet, useWindowSize } from '~/hooks/@global'
import { ReactComponent as BusdToken } from '~/assets/svgs/icon-token/busd.svg'
import { useCallback } from 'react'
import { usePoolAff } from '~/hooks/pool-aff';
import ConnectWalletButton from '~/layouts/ConnectWalletButton';


export const MintDetailView = () => {
    const { width } = useWindowSize();
    const navigate = useNavigate();
    const { id } = useParams();
    const { account } = useConnectWallet();

    const { isApprove, isLoading, approve, qty, setQty, mint } = usePoolAff({ nftIdRef: id });

    const renderPriceAndItemMint = useCallback(() => {
        return (
            <HStack
                pt={{ base: "20px", }}
                spacing={10}
                w="full"
            >
                <VStack alignItems={"start"}>
                    <Text
                        color="#fff"
                        fontWeight="300"
                        fontSize={{ base: "20px", md: "20px" }}
                        lineHeight={{ base: "129.5%", md: "129.5%" }}
                        textAlign='left'
                    >
                        Price mint
                    </Text>
                    <HStack>
                        <Icon as={BusdToken} h="24px" w="24px" />
                        <Text
                            color="#D917E2"
                            fontWeight="700"
                            fontSize={{ base: "33px", md: "35px" }}
                            lineHeight={{ base: "40px", md: "44px" }}
                            textTransform="uppercase"
                        >
                            100 BUSD
                        </Text>
                    </HStack>
                </VStack>
                {/* <VStack alignItems={"start"} pl="50px">
                    <Text
                        color="#fff"
                        fontWeight="300"
                        fontSize={{ base: "20px", md: "20px" }}
                        lineHeight={{ base: "129.5%", md: "129.5%" }}
                        textAlign='left'
                    >
                        Items
                    </Text>
                    <HStack>
                        <Text
                            color="#D917E2"
                            fontWeight="700"
                            fontSize={{ base: "33px", md: "35px" }}
                            lineHeight={{ base: "40px", md: "44px" }}
                            textTransform="uppercase"
                        >
                            798
                            <span style={{ color: "#fff", fontSize: "20px", lineHeight: '129.5%' }}>
                                /800
                            </span>
                        </Text>
                    </HStack>
                </VStack> */}
            </HStack>
        )
    }, [])

    const renderInputBuyMint = useCallback(() => {
        return (
            <VStack
                mt="27px !important"
                bg="linear-gradient(126.12deg, rgba(255, 0, 255, 0.29) -1.57%, rgba(255, 28, 247, 0) 109.63%)"
                borderRadius={"5px"}
                minW={{ base: "full", md: "512px" }}
                alignItems={{ base: 'center', md: 'start' }}
                p="28px 31px"
            >

                <HStack spacing={0} ml="-20px !important">
                    <Button
                        bg="transparent"
                        _focus={{
                            backgroundColor: 'transparent',
                        }}
                        _hover={{
                            backgroundColor: 'transparent',
                        }}
                        _active={{
                            backgroundColor: 'transparent',
                        }}
                        onClick={() => {
                            console.log("decrement");
                            setQty(qty - 1)
                        }}
                    >
                        <Image src="/svgs/MinusIcon.svg" objectFit={'cover'} />
                    </Button>
                    <Input
                        w="80px"
                        color="white"
                        fontSize={"20px"}
                        lineHeight="20px"
                        fontWeight={700}
                        bg="transparent"
                        borderColor={"transparent"}
                        focusBorderColor="transparent"
                        _hover={{
                            borderColor: "transparent"
                        }}
                        value={qty}
                        onChange={(e) => setQty(Number(e.target.value))}
                        pattern={`^[0-9]*[.,]?[0-9]{0,10}$`}
                        type="number"
                    />
                    <Button
                        bg="transparent"
                        _focus={{
                            backgroundColor: 'transparent',
                        }}
                        _hover={{
                            backgroundColor: 'transparent',
                        }}
                        _active={{
                            backgroundColor: 'transparent',
                        }}
                        onClick={() => {
                            console.log("increment");
                            setQty(qty + 1)
                        }}
                    >
                        <Image src="/svgs/PlusIcon.svg" objectFit={'cover'} />
                    </Button>
                </HStack>

                <HStack
                    pt="15px"
                    alignItems={"end"}
                >
                    <Text
                        color="#fff"
                        fontWeight="300"
                        fontSize={{ base: "20px", md: "20px" }}
                        lineHeight={{ base: "129.5%", md: "129.5%" }}
                        textAlign='left'
                    >
                        Total
                    </Text>
                    <Text
                        color="#fff"
                        fontWeight="700"
                        fontSize={{ base: "33px", md: "35px" }}
                        lineHeight={{ base: "34px", md: "34px" }}
                        textTransform="uppercase"
                    >
                        {qty} box
                    </Text>
                    <Text
                        color="#fff"
                        fontWeight="300"
                        fontSize={{ base: "20px", md: "20px" }}
                        lineHeight={{ base: "129.5%", md: "129.5%" }}
                        textAlign='left'
                    >
                        = ${(qty * 100)} BUSD
                    </Text>
                </HStack>

            </VStack>
        )
    }, [qty, setQty])

    const renderButtonMint = useCallback(() => {
        if (!account) {
            return <ConnectWalletButton />
        }

        if (!isApprove) {
            return (
                <Button
                    w="full"
                    background="linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)"
                    _focus={{
                        backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
                    }}
                    _hover={{
                        backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
                    }}
                    padding="7.59494px 30.3797px"
                    borderRadius="5px"
                    color="#fff"
                    isLoading={isLoading}
                    onClick={approve}
                >
                    Approve $BUSD
                </Button>
            )
        }
        return (
            <Button
                w="full"
                background="linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)"
                _focus={{
                    backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
                }}
                _hover={{
                    backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
                }}
                padding="7.59494px 30.3797px"
                borderRadius="5px"
                color="#fff"
                disabled={!id}
                isLoading={isLoading}
                onClick={mint}
            >
                Mint
            </Button>
        )
    }, [account, approve, id, isApprove, isLoading, mint])

    return (
        <Box>
            <Box h={"23vh"} />

            <Grid
                p={{ base: "20px", md: "auto" }}
                templateColumns='repeat(12, 1fr)'
                gap={{ base: 0, lg: 10 }}
            >
                <GridItem colSpan={{ base: 12, md: 3, lg: 4, xl: 6 }}>
                    <Image
                        src={"https://app.moverse.biz/media/prod/mvs-image-res-01/upload/mvs/res/544acc39-ae21-440b-9178-ab88d9d86cac.png"}
                    />
                </GridItem>

                <GridItem colSpan={{ base: 12, md: 9, lg: 8, xl: 6 }}>

                    <VStack
                        alignItems={"start"}
                        pt={{ base: "10px", xl: "40px" }}
                        pl={{ base: "10px", md: "40px" }}
                    >

                        <Text
                            color="#D917E2"
                            fontWeight="800"
                            fontSize={{ base: "30px", md: "35px" }}
                            lineHeight={{ base: "40px", md: "44px" }}
                            textTransform="uppercase"
                            pt={{ base: "40px", md: "0px" }}
                            maxW={{ base: "full", md: "500px" }}
                        >
                            Moverse NFT affiliate
                        </Text>
                        <Text
                            color="#fff"
                            fontWeight="300"
                            fontSize={{ base: "16px", md: "20px" }}
                            lineHeight={{ base: "129.5%", md: "129.5%" }}
                            maxW={{ base: "full", md: "500px" }}
                            textAlign='left'
                        >
                            Create new nft and refer your friends to get more offers
                        </Text>

                        {renderPriceAndItemMint()}

                        {renderInputBuyMint()}

                        {renderButtonMint()}

                    </VStack>

                </GridItem>

            </Grid>


            <Box h={{ base: "10vh", lg: "50vh" }} />

        </Box>
    )
}
