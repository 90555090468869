import { Image, Flex, VStack, Text, HStack, Box } from "@chakra-ui/react"
import LogoWeb from '~/assets/svgs/header/logo.png'
import { useWindowSize } from "~/hooks/@global"
import { SocialMoverse } from "./SocialMoverse"

const Footer = () => {
  const { width } = useWindowSize();

  const renderLogo = () => {
    return (
      <VStack alignItems={{ base: "center", md: "start" }}>
        <Image
          src={LogoWeb}
          height="47px"
          w={"192px"}
        />
        <Text
          fontSize="18px"
          maxW="700px"
          textAlign="left"
          fontWeight="500"
          color="#fff"
        >
          Copyright © 2022 Moverse Ltd
        </Text>
      </VStack>
    )
  }

  const renderContent = () => {
    return (
      <>
        <SocialMoverse />

        <VStack alignItems={{ base: "center", lg: "start" }}>
          <Text
            fontSize="18px"
            maxW="700px"
            textAlign={{ base: "center", lg: "left" }}
            fontWeight="500"
            color="#fff"
            cursor="pointer"
          >
            Pitchdeck
          </Text>
          <Text
            fontSize="18px"
            maxW="700px"
            textAlign={{ base: "center", lg: "left" }}
            fontWeight="500"
            color="#fff"
            cursor="pointer"
          >
            Whitepaper
          </Text>
        </VStack>

        <VStack alignItems={{ base: "center", lg: "start" }}>
          <Text
            fontSize="18px"
            maxW="700px"
            textAlign={{ base: "center", lg: "left" }}
            fontWeight="500"
            color="#fff"
            cursor="pointer"
          >
            Litepaper
          </Text>
          <Text
            fontSize="18px"
            maxW="700px"
            textAlign={{ base: "center", lg: "left" }}
            fontWeight="500"
            color="#fff"
            cursor="pointer"
          >
            Become influencer
          </Text>
        </VStack>
      </>
    )
  }

  return (
    <VStack w="100%" pb="20vh" spacing={{ base: 10, md: 2 }}>
      {width <= 820 ?
        <>
          {renderContent()}
          {renderLogo()}
        </>
        :
        <Flex
          h="100%"
          alignItems={'center'}
          justifyContent={'space-between'}
          maxW="1200px"
          w="full"
        >
          {renderLogo()}
          {renderContent()}
        </Flex>
      }

    </VStack>
  )
}

export default Footer
