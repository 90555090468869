import { Box, Text, HStack, VStack, Button, Tab, TabList, TabPanel, TabPanels, Tabs, Image } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import TextVestingImage from '~/assets/svgs/vesting.png'
import { useWindowSize } from '~/hooks/@global'

const ItemRowInfo = (props: { title: string, value: string | number }) => {
    const { title, value } = props;
    return (
        <HStack
            justifyContent="space-between"
            w="full"
            pb="8px"
            borderBottom="0.949367px solid #6B6785"
        >
            <Text
                fontWeight="400"
                fontSize="15px"
                lineHeight="19px"
                color="#fff"
                textAlign="left"
            >
                {title}
            </Text>
            <Text
                fontWeight="400"
                fontSize="15px"
                lineHeight="19px"
                color="#fff"
                textAlign="right"
            >
                {value}
            </Text>
        </HStack>
    )
}

export const VestingView = () => {
    const { width } = useWindowSize();
    const navigate = useNavigate();

    const renderTab = () => {
        return (
            <Box
                cursor="pointer"
                borderColor="#6B6785"
                borderWidth="0.949367px"
                borderStyle="solid"
                background="rgba(26, 0, 60, 0.3)"
                borderRadius="12px"
                p={{ base: "15px", md: "45px" }}
            >

                <VStack mt="20px">
                    <ItemRowInfo title='Vested balance' value={'TBA'} />
                    <ItemRowInfo title='Month claimed' value={'TBA'} />
                    <ItemRowInfo title='Total MOVE claimed' value={'TBA'} />
                    <ItemRowInfo title='Next claimble on' value={"TBA"} />
                    <ItemRowInfo title='Amount of MOVE that can be claimed' value={"TBA"} />
                    <ItemRowInfo title='Claimed at TGE' value={"TBA"} />
                </VStack>

                <VStack mt="20px">

                    <Button
                        background="linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)"
                        _focus={{
                            backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
                        }}
                        _hover={{
                            backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
                        }}
                        padding="7.59494px 30.3797px"
                        borderRadius="5px"
                        color="#fff"
                        w="244px"
                    >
                        Claim
                    </Button>

                </VStack>
            </Box>
        )
    }

    return (
        <Box>
            <Box h={"15vh"} />
            <VStack
                bg="transparent"
                w="full"
                mt={{ base: "50px", md: "100px" }}
                mb="90px"
                px={{
                    base: "10px",
                    md: "0px"
                }}
            >
                <Image src={TextVestingImage} />
            </VStack>

            <Tabs variant='unstyled' pt={{ base: "0px", md: "45px" }} isFitted>
                <VStack>
                    <TabList>
                        <Tab
                            color="#fff"
                            _selected={{ bg: "primary" }}
                            fontWeight={500}
                            fontSize={{ base: "16px", md: "26px" }}
                            lineHeight='23px'
                            border="1px solid #FF00FF"
                            p={{ base: "5px", lg: "11px 51px" }}
                        >
                            PRIVATE SALE
                        </Tab>
                        <Tab
                            color="#fff"
                            _selected={{ bg: "primary" }}
                            fontWeight={500}
                            fontSize={{ base: "16px", md: "26px" }}
                            lineHeight='23px'
                            border="1px solid #FF00FF"
                            p={{ base: "5px", lg: "11px 51px" }}
                        >
                            PARTNERSHIP
                        </Tab>
                        <Tab
                            color="#fff"
                            _selected={{ bg: "primary" }}
                            fontWeight={500}
                            fontSize={{ base: "16px", md: "26px" }}
                            lineHeight='23px'
                            border="1px solid #FF00FF"
                            p={{ base: "5px", lg: "11px 51px" }}
                        >
                            AIRDROP
                        </Tab>
                    </TabList>
                </VStack>

                <TabPanels pt={{ base: "15px", md: "45px" }}>
                    <TabPanel>
                        {renderTab()}
                    </TabPanel>
                    <TabPanel>
                        {renderTab()}
                    </TabPanel>
                    <TabPanel>
                        {renderTab()}
                    </TabPanel>
                </TabPanels>
            </Tabs>

            <Box h={{ base: "10vh", lg: "50vh" }} />

        </Box>
    )
}
