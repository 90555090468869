import { Contract } from "ethers";
import { useCallback, useEffect, useState } from "react"
import FaucetTokenTestnet__factory from "~/common/abis/FaucetTokenTestnet__factory";
import ChainConfig from "~/common/constants/ChainConfig";
import { useAlert, useConnectWallet } from "../@global";



const FAUCET_TESTNET_ADDRESS = "0x996A0D019b6Edd04CA394Ae752EF733e13c5dFF4";

export const useFaucetTestnet = () => {
    const toast = useAlert();
    const { library, account } = useConnectWallet();
    const [isLoading, setLoading] = useState(false);
    const [granted, setGranted] = useState(false)
    const loadGranted = useCallback(async () => {

        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const poolCt = new Contract(
                FAUCET_TESTNET_ADDRESS,
                FaucetTokenTestnet__factory.abi,
                library
            )
            const grantedToken = await poolCt.viewGrantedClaim(account);

            setGranted(grantedToken);
        } catch (error) {
        }
    }, [account, library]);

    useEffect(() => {
        loadGranted();
    }, [loadGranted])

    const faucet = useCallback(async () => {
        setLoading(true);
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const sender = library.getSigner(account);
            const poolCt = new Contract(
                FAUCET_TESTNET_ADDRESS,
                FaucetTokenTestnet__factory.abi,
                library
            )
            const { transactionHash } = await (
                await (poolCt.connect(sender).faucet())
            ).wait();
            loadGranted();
            toast.success(`${ChainConfig.getUrlScan()}/tx/${transactionHash}`);

        } catch (error) {
            toast.handleErrorBlockChain(error)
        }
        setLoading(false);
    }, [account, library, loadGranted, toast]);


    return {
        isLoading,
        granted,
        faucet
    }
}