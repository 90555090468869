import React from 'react'
import {
  Box,
  Center,
  Flex,
} from '@chakra-ui/react'
import Header from './Header'
import Footer from './Footer'
import ImgHome from '~/assets/svgs/background_app.png'

const MainLayoutCustom: React.FC = ({ children }) => {
  return (
    <Flex overflow="hidden" position="relative" h="100vh">
      <Header />
      <Box
        backgroundImage={ImgHome}
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        overflowY="auto"
        width="100%"
        paddingLeft={"200px"}
        position="relative"
      >
        <Box w="full" alignItems="center">
          <Center>
            <Box
              w="full"
              maxW={{ base: "auto", lg: "1200px" }}
            >
              {children}
            </Box>
          </Center>
        </Box>

        <Footer />
      </Box>
    </Flex >
  )
}

export default MainLayoutCustom
