import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  HStack,
  Image,
  VStack,
  Box,
  Icon,
  Divider,
  Input
} from '@chakra-ui/react'
import { ReactComponent as VerifyIcon } from '~/assets/svgs/components/verify.svg';
import { formatMoney, formatMoneyInput } from '~/@helpers/format.helper';
import { useCallback, useState } from 'react';
import { useConnectWallet } from '~/hooks/@global';

interface ILoginModal {
  isLoading: boolean;
  isOpen?: boolean
  onDismiss?: () => void
  login?: (email: string, password: string, reloadData?: () => Promise<void>) => Promise<void>;
}

const LoginModal: React.FC<ILoginModal> = ({
  isLoading,
  login,
  isOpen,
  onDismiss: onClose,
}) => {

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");


  const onClickLogin = useCallback(async () => {
    await login(email, password, async () => {
      onClose();
    });

  }, [email, login, onClose, password])

  const renderBtn = useCallback(() => {
    return (
      <Button
        background="linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)"
        _focus={{
          backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
        }}
        _hover={{
          backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
        }}
        padding="7.59494px 30.3797px"
        borderRadius="5px"
        color="#fff"
        w="full"
        isLoading={isLoading}
        onClick={onClickLogin}
      >
        Login
      </Button>
    )
  }, [isLoading, onClickLogin])

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent maxW="729px" mx="20px">
        <ModalHeader
          fontSize="18px"
          fontWeight="500"
          letterSpacing="-0.5px"
          lineHeight="21px"
          px={{
            base: '10px',
            lg: '20px',
          }}
          py="12px"
        >
          Login account app
        </ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody
          px={{
            base: '20px',
            lg: '40px',
          }}
          py="30px"
        >
          <Text
            mt="20px"
            fontWeight="400"
            fontSize="18px"
            lineHeight="20px"
            color="grey.66"
            mb="8px"
            letterSpacing="-0.5px"
          >
            Email
          </Text>
          <Input
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
            borderColor="#448AFF !important"
            borderWidth="1px"
            borderStyle="solid"
            placeholder={"Email"}
            borderRadius="10px"
            textAlign="left"
            fontWeight="500"
            mr="106px"
            fontSize="32px"
            lineHeight="20px"
            h="56px"
            bg="#F7F9FA"
          />

          <Text
            mt="20px"
            fontWeight="400"
            fontSize="18px"
            lineHeight="20px"
            color="grey.66"
            mb="8px"
            letterSpacing="-0.5px"
          >
            Password
          </Text>
          <Input
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            type={"password"}
            borderColor="#448AFF !important"
            borderWidth="1px"
            borderStyle="solid"
            placeholder={"Password"}
            borderRadius="10px"
            textAlign="left"
            fontWeight="500"
            mr="106px"
            fontSize="32px"
            lineHeight="20px"
            h="56px"
            bg="#F7F9FA"
          />

          {/* <Text
            mt="12px"
            fontSize="18px"
            lineHeight="21px"
            fontWeight="600"
            color="black.1d"
            letterSpacing="-0.5px"
          >
            Link download app
          </Text> */}
        </ModalBody>

        <ModalFooter w="100%" mt="30px">
          <HStack flex={1} w="100%">
            <Box flex={1}>{renderBtn()}</Box>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default LoginModal
