
import { SimpleGrid } from '@chakra-ui/react'
import { useMyNft, useMyOrder } from '~/hooks/market'
import { MyOrderItem } from './MyOrderItem';



export const MyOrder = () => {
    const { list, loadData } = useMyOrder();
    return (
        <SimpleGrid
            columns={{ base: 1, md: 2, xl: 4 }}
            spacing={4}
        >
            {list.map((item, idx) => {
                return (
                    <MyOrderItem {...item}
                        loadData={loadData}
                    />
                )
            })}
        </SimpleGrid>
    )
}