import { VStack, Text, HStack, Image, SimpleGrid, Input, InputGroup, Button, Tag } from "@chakra-ui/react"
import CalendarImage from '~/assets/svgs/calendar.svg'
import ArrowUpImage from '~/assets/svgs/arrow-up.svg'
import { usePoolAuto } from "~/hooks/pool-auto";
import { useCallback } from "react";
import { useConnectWallet } from "~/hooks/@global";
import ConnectWalletButton from "~/layouts/ConnectWalletButton";
import { formatMoney, formatMoneyInput } from "~/@helpers/format.helper";

const ItemRowInfo = (props: { title: string, value: string | number }) => {
    const { title, value } = props;
    return (
        <HStack
            justifyContent="space-between"
            w="full"
            pb="8px"
        >
            <Text
                fontWeight="400"
                fontSize="15px"
                lineHeight="19px"
                color="#fff"
                textAlign="left"
            >
                {title}
            </Text>
            <Text
                fontWeight="400"
                fontSize="15px"
                lineHeight="19px"
                color="#fff"
                textAlign="right"
            >
                {value}
            </Text>
        </HStack>
    )
}

export const FlexibleStaking = () => {

    const { account } = useConnectWallet();
    const { isApprove, isLoading, approve, redeem, stake, withdraw, amount, setAmount,
        apr,
        isLoadingWithdraw,
        isLoadingRedeem,
        userInfo, poolInfo } = usePoolAuto();



    const renderBtnStake = useCallback(() => {
        if (!account) {
            return <ConnectWalletButton />
        }

        if (!isApprove) {
            return (
                <Button
                    w="full"
                    background="linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)"
                    _focus={{
                        backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
                    }}
                    _hover={{
                        backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
                    }}
                    padding="7.59494px 30.3797px"
                    borderRadius="5px"
                    color="#fff"
                    isLoading={isLoading}
                    onClick={approve}
                >
                    Approve $MOVE
                </Button>
            )
        }

        return (
            <Button
                w="full"
                background="linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)"
                _focus={{
                    backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
                }}
                _hover={{
                    backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
                }}
                padding="7.59494px 30.3797px"
                borderRadius="5px"
                color="#fff"
                isLoading={isLoading}
                onClick={stake}
            >
                Stake
            </Button>
        )
    }, [account, approve, isApprove, isLoading, stake]);



    const renderBtnWithdraw = useCallback(() => {
        return (
            <Button
                w="full"
                border="1px solid #FF1CF7"
                background="transparent"
                _focus={{
                    backgroundColor: "transparent",
                }}
                _hover={{
                    backgroundColor: "transparent",
                }}
                borderRadius="5px"
                color="#fff"
                disabled={!account}
                isLoading={isLoadingWithdraw}
                onClick={withdraw}
            >
                Withdraw
            </Button>
        )
    }, [account, isLoadingWithdraw, withdraw]);

    const renderBtnRedeem = useCallback(() => {
        return (
            <Button
                w="full"
                border="1px solid #FF1CF7"
                background="transparent"
                _focus={{
                    backgroundColor: "transparent",
                }}
                _hover={{
                    backgroundColor: "transparent",
                }}
                borderRadius="5px"
                color="#fff"
                disabled={!account}
                isLoading={isLoadingRedeem}
                onClick={redeem}
            >
                Redeem sneaker box
            </Button>
        )
    }, [account, isLoadingRedeem, redeem]);
    return (
        <VStack pt="100px" w="full">
            <Text
                color="#fff"
                fontWeight="900"
                fontSize={{ base: "33px", md: "35px" }}
                lineHeight={{ base: "40px", md: "44px" }}
                textTransform="uppercase"
            >
                Flexible Staking
            </Text>

            <Text
                color="#fff"
                fontWeight="300"
                fontSize={{ base: "16px", md: "20px" }}
                lineHeight="23px"
                maxW="855px"
                textAlign="center"
                py="30px"
            >
                Once staked, you can withdraw anytime and can swap a  sneaker box with 1.000.000.000 tickets
            </Text>

            <VStack
                w="full"
                cursor="pointer"
                borderColor="#6B6785"
                borderWidth="0.949367px"
                borderStyle="solid"
                background="rgba(26, 0, 60, 0.3)"
                _hover={{
                    borderColor: "primary",
                    background: "linear-gradient(126.12deg, rgba(255, 0, 255, 0.2) -1.57%, rgba(255, 28, 247, 0) 109.63%)",
                    backdropFilter: "blur(5.6962px)"
                }}
                px="23px"
                py="20px"
            >
                <HStack w="full" justifyContent="space-between">
                    <HStack
                        bg="primary"
                        boxShadow="0px 0px 16px rgba(255, 0, 255, 0.69)"
                        padding="2px 8px"
                        w="fit-content"
                    >
                        <Image src={CalendarImage} h="20px" w="24px" />
                        <Text
                            fontWeight="500"
                            fontSize="18px"
                            lineHeight="160%"
                            color="#fff"
                            textTransform="uppercase"
                        >
                            ANY TIME
                        </Text>
                    </HStack>
                    <Text
                        color="#fff"
                        fontWeight="400"
                        fontSize="26px"
                        lineHeight="120%"
                        textTransform="uppercase"
                    >
                        APR {apr}
                    </Text>
                </HStack>

                <Text
                    color="primary"
                    fontWeight="400"
                    fontSize="33px"
                    lineHeight="120%"
                    textTransform="uppercase"
                    alignSelf="start"
                    py="15px"
                >
                    Total rewards: {"500,000,000"} tickets
                </Text>

                <HStack
                    justifyContent="center"
                    w="full"
                    pb="8px"
                >
                    <Text
                        fontWeight="400"
                        fontSize="15px"
                        lineHeight="19px"
                        color="#fff"
                        textAlign="left"
                    >
                        {"Start"}
                    </Text>
                    <Text
                        fontWeight="400"
                        fontSize="15px"
                        lineHeight="19px"
                        color="primary"
                        textAlign="right"
                    >
                        {poolInfo.startTime} UTC
                    </Text>
                    <Text
                        fontWeight="400"
                        fontSize="15px"
                        lineHeight="19px"
                        color="#fff"
                        textAlign="left"
                    >
                        {"End"}
                    </Text>
                    <Text
                        fontWeight="400"
                        fontSize="15px"
                        lineHeight="19px"
                        color="primary"
                        textAlign="right"
                    >
                        {poolInfo.endTime} UTC
                    </Text>
                </HStack>
                <HStack
                    justifyContent="center"
                    w="full"
                    pb="8px"
                >
                    <Text
                        fontWeight="400"
                        fontSize="15px"
                        lineHeight="30px"
                        p="5px"
                        color="#fff"
                        background="linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)"
                        textAlign="right"
                    >
                        {" 1,000,000 TICKETS = 1 SNEAKER BOX "}
                    </Text>

                </HStack>
                <SimpleGrid
                    columns={{
                        base: 1,
                        md: 2,
                    }}
                    spacing={{
                        base: 5,
                    }}
                    w="full"
                >
                    <VStack mt="20px">

                        <ItemRowInfo title='Total locked value' value={`${poolInfo.totalValueLocked} $MOVE`} />
                        <ItemRowInfo title='Total user staked' value={poolInfo.totalUserStaked} />
                        <ItemRowInfo title='My staked' value={`${userInfo.amountStaked} $MOVE`} />
                        <ItemRowInfo title='My reward' value={`${userInfo.pendingReward} TICKETS`} />
                        <ItemRowInfo title='My redeemed sneaker box' value={userInfo.qtyRedeem} />

                        <HStack alignSelf={{ base: "center", md: "end" }}>
                            <Text
                                fontWeight={500}
                                fontSize="14px"
                                lineHeight="160%"
                                color="primary"
                            >
                                How to earn ticket
                            </Text>
                            <Image src={ArrowUpImage} />
                        </HStack>
                    </VStack>

                    <VStack mt={{ base: "0px", md: "20px" }} spacing={6}>
                        <VStack alignItems="start" w="full">
                            <Text
                                fontWeight={300}
                                fontSize="18px"
                                lineHeight="23px"
                                color="#fff"
                            >
                                Amount stake or widthdraw:
                            </Text>
                            <HStack w="full">
                                <InputGroup>
                                    <Input
                                        background="rgba(128, 19, 162, 0.5)"
                                        boxShadow="inset 0px 0px 11.1609px rgba(0, 0, 0, 0.1)"
                                        borderRadius="7.44062px"
                                        color="#fff"
                                        borderColor="transparent"
                                        pattern={`^[0-9]*[.,]?[0-9]{0,10}$`}
                                        value={formatMoneyInput(amount)}
                                        onChange={(e) => {
                                            setAmount(e.target.value.replace(/[^0-9.]/g, ''))
                                        }}
                                    />
                                </InputGroup>
                                <Text
                                    fontWeight={400}
                                    fontSize="18px"
                                    lineHeight="23px"
                                    color="primary"
                                >
                                    $MOVE
                                </Text>
                            </HStack>
                        </VStack>


                        <SimpleGrid
                            columns={{
                                base: 1,
                                md: 3,
                            }}
                            spacing={{
                                base: 5,
                            }}
                            w="full"
                        >
                            {renderBtnStake()}
                            {renderBtnWithdraw()}
                            {renderBtnRedeem()}
                        </SimpleGrid>
                        <VStack
                            justifyContent="center"
                            w="full"
                            pb="8px"
                        >
                            <Text
                                fontWeight="400"
                                fontSize="15px"
                                lineHeight="19px"
                                color="#fff"
                                textAlign="left"
                            >
                                {"My redeemable sneaker box"}
                            </Text>
                            <Text
                                fontWeight="400"
                                fontSize="25px"
                                lineHeight="19px"
                                color="primary"
                                textAlign="right"
                            >
                                {userInfo.qtyRedeemable}
                            </Text>

                        </VStack>
                    </VStack>

                </SimpleGrid>

            </VStack>

        </VStack>
    )
}