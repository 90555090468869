import { BigNumber, BigNumberish, constants, Contract } from "ethers";
import { useCallback, useEffect, useState } from "react"
import { configEnv } from "~/@config";
import ERC20__factory from "~/common/abis/ERC20__factory";
import ERC721__factory from "~/common/abis/ERC721__factory";
import Marketplace__factory from "~/common/abis/Marketplace__factory";
import ChainConfig from "~/common/constants/ChainConfig";
import { useAlert, useConnectWallet } from "../@global";


const { ADDRESS_CONFIG } = configEnv();

const { MARKET_ADDRESS, MOVERSE_NFT: collection, MOVE } = ADDRESS_CONFIG;
export const useMarketCollection = () => {
    const toast = useAlert();
    const { account, library } = useConnectWallet()
    const [isLoading, setLoading] = useState(false)
    const [isApproveCollection, setIsApproveCollection] = useState(false)
    const [isApprove, setIsApprove] = useState(false);


    const approve = useCallback(async () => {
        setLoading(true);
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const signer = library.getSigner(account);
            const tokenCt = new Contract(
                MOVE,
                ERC20__factory.abi,
                library
            )
            const { transactionHash } = await (await tokenCt.connect(signer).approve(
                MARKET_ADDRESS,
                constants.MaxInt256
            )).wait();
            setIsApprove(true);
            toast.success(`${ChainConfig.getUrlScan()}/tx/${transactionHash}`);
        } catch (error) {
            toast.handleErrorBlockChain(error)
        }
        setLoading(false);
    }, [account, library]);

    const allowance = useCallback(async () => {

        setLoading(true);
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }

            console.log(`-------------------`);
            console.log({
                MOVE
            });
            console.log(`-------------------`);

            const tokenCt = new Contract(
                MOVE,
                ERC20__factory.abi,
                library
            )
            const data: BigNumber = await tokenCt.allowance(
                account,
                MARKET_ADDRESS,
            );

            console.log(`-------------------`);
            console.log(data);
            console.log(`-------------------`);
            setIsApprove(data.gt(BigNumber.from(0)));
        } catch (error) {
            toast.handleErrorBlockChain(error)
        }
        setLoading(false);


    }, [account, library]);


    const approveCollection = useCallback(async () => {
        setLoading(true)
        try {
            if (!account || !library) {
                throw new Error('Acount empty')
            }
            const signer = library?.getSigner()
            const erc721Ct = new Contract(collection, ERC721__factory.abi, library)
            const { transactionHash } = await (
                await erc721Ct
                    .connect(signer)
                    .setApprovalForAll(MARKET_ADDRESS, true)
            ).wait()
            setIsApproveCollection(true)
            toast.success(`${ChainConfig.getUrlScan()}/tx/${transactionHash}`);
            setLoading(false)
        } catch (error) {
            toast.handleErrorBlockChain(error)
        }
        setLoading(false)
    }, [account, library])

    const allowanceCollection = useCallback(async () => {
        setLoading(true)
        try {
            if (!account || !library) {
                throw new Error('Acount empty')
            }
            const erc721Ct = new Contract(collection, ERC721__factory.abi, library)
            const check = await erc721Ct.isApprovedForAll(account, MARKET_ADDRESS)
            console.log('----------allowanceNft---------')
            console.log({ check })
            console.log('-------------------')
            setIsApproveCollection(check)
        } catch (error) {
            console.log(error)
            toast.handleErrorBlockChain(error);
        }
        setLoading(false)
    }, [account, library])

    useEffect(() => {
        allowanceCollection()
    }, [allowanceCollection])

    useEffect(() => {
        allowance()
    }, [allowance])

    /**
     * Đăng bán nft
     */
    const askListing = useCallback(
        async (tokenId: number, price: BigNumber, reloadData?: () => Promise<void>) => {
            setLoading(true)
            try {
                const sender = library.getSigner(account)
                const marketCt = new Contract(
                    MARKET_ADDRESS,
                    Marketplace__factory.abi,
                    library,
                );

                if (price.lte(0)) {
                    throw new Error("Invalid price");
                }
                const { transactionHash } = await (
                    await marketCt.connect(sender).askListing(collection, tokenId, price)
                ).wait()
                toast.success(`${ChainConfig.getUrlScan()}/tx/${transactionHash}`);
                if (reloadData) {
                    reloadData();
                }
                setLoading(false);
            } catch (error) {
                toast.handleErrorBlockChain(error)
            }
            setLoading(false)

        },
        [account, library],
    )

    /**
     * Hủy đăng bán
     */
    const askCancelListing = useCallback(
        async (askId: number, reloadData?: () => Promise<void>) => {
            setLoading(true)
            try {
                const sender = library.getSigner(account)
                const marketCt = new Contract(
                    MARKET_ADDRESS,
                    Marketplace__factory.abi,
                    library,
                )
                const { transactionHash } = await (
                    await marketCt.connect(sender).askCancelListing(askId)
                ).wait()
                toast.success(`${ChainConfig.getUrlScan()}/tx/${transactionHash}`);
                if (reloadData) {
                    reloadData();
                }
                setLoading(false)

            } catch (error) {
                toast.handleErrorBlockChain(error)
            }
            setLoading(false)

        },
        [account, library],
    )

    /**
     * Thay đổi giá
     */
    const askUpdatePrice = useCallback(
        async (askId: number, price: BigNumber, reloadData?: () => Promise<void>) => {
            setLoading(true)
            try {
                if (price.lte(0)) {
                    throw new Error("Invalid price");
                }
                const sender = library.getSigner(account)
                const marketCt = new Contract(
                    MARKET_ADDRESS,
                    Marketplace__factory.abi,
                    library,
                )
                const { transactionHash } = await (
                    await marketCt.connect(sender).askUpdatePrice(askId, price)
                ).wait()
                toast.success(`${ChainConfig.getUrlScan()}/tx/${transactionHash}`);
                if (reloadData) {
                    reloadData();
                }
                setLoading(false)

            } catch (error) {
                toast.handleErrorBlockChain(error);
            }

            setLoading(false)

        },
        [account, library],
    )

    /**
     * Mua nft
     */
    const askSale = useCallback(
        async (askId: number, price: BigNumberish, reloadData?: () => Promise<void>) => {
            setLoading(true)
            try {
                const sender = library.getSigner(account)
                const marketCt = new Contract(
                    MARKET_ADDRESS,
                    Marketplace__factory.abi,
                    library,
                )
                const { transactionHash } = await (
                    await marketCt.connect(sender).askSale(askId, price)
                ).wait()
                toast.success(`${ChainConfig.getUrlScan()}/tx/${transactionHash}`);
                if (reloadData) {
                    reloadData()
                }
                setLoading(false);
            } catch (error) {
                toast.handleErrorBlockChain(error);
            }
            setLoading(false)
        },
        [account, library],
    )



    /**
     * Gửi NFT
     */

    const sendNft = useCallback(
        async (tokenId: number, receiptAddress: string, reloadData?: () => Promise<void>) => {
            setLoading(true)
            try {
                const sender = library.getSigner(account)
                const erc721Ct = new Contract(collection, ERC721__factory.abi, library)
                const { transactionHash } = await (
                    await erc721Ct
                        .connect(sender)
                        .transferFrom(account, receiptAddress, tokenId)
                ).wait()
                toast.success(`${ChainConfig.getUrlScan()}/tx/${transactionHash}`);
                if (reloadData) {
                    reloadData();
                }
                setLoading(false)
            } catch (error) {
                toast.handleErrorBlockChain(error);
            }
            setLoading(false)
            return false
        },
        [account, library],
    )

    return {
        isLoading,
        isApprove,
        approve,
        isApproveCollection,
        approveCollection,
        askListing,
        askCancelListing,
        askUpdatePrice,
        askSale,
        sendNft,
    }
}