import { Box, VStack, Image, Text, Tab, TabList, TabPanel, TabPanels, Tabs, HStack, SimpleGrid } from '@chakra-ui/react'
import { useWindowSize } from '~/hooks/@global';
import TextHistoryImage from '~/assets/svgs/text-history.png'
import { HistoryItem } from './HistoryItem';
import { useOrderStaked } from '~/hooks/pool-fixed';

const ItemRowHeader = (props: {
    title: string
}) => {
    const { title } = props;
    return (
        <VStack
            w="full"
            alignItems="start"
        >
            <Text
                fontWeight="400"
                fontSize="15px"
                lineHeight="19px"
                color="rgba(255, 255, 255, 0.7)"
            >
                {title}
            </Text>
        </VStack>
    )
}

export const HistoryView = () => {
    const { width } = useWindowSize();

    const { orders, withdraw } = useOrderStaked();

    const renderTab = () => {
        return (
            <VStack spacing={6}>
                <HStack
                    w="full"
                    p="5px"
                    _hover={{
                        borderColor: "primary",
                        background: "linear-gradient(126.12deg, rgba(255, 0, 255, 0.2) -1.57%, rgba(255, 28, 247, 0) 109.63%)",
                    }}
                    borderColor={{ base: "primary", md: "transparent" }}
                    background={{ base: "linear-gradient(126.12deg, rgba(255, 0, 255, 0.2) -1.57%, rgba(255, 28, 247, 0) 109.63%)", md: "transparent" }}
                >
                    <ItemRowHeader title='Package' />
                    <ItemRowHeader title='Amount' />
                    <ItemRowHeader title='Time stake' />
                    <ItemRowHeader title='End time lock' />
                    <ItemRowHeader title='Action' />
                </HStack>
                {orders.map((item, idx) =>
                    <HistoryItem {...item} withdraw={withdraw} />
                )}
            </VStack>
        )
    }

    const renderTablist = () => {
        return (
            <VStack pb="50px">
                <TabList>
                    <Tab
                        color="#fff"
                        _selected={{ bg: "primary" }}
                        fontWeight={500}
                        fontSize={{ base: "16px", md: "26px" }}
                        lineHeight='23px'
                        border="1px solid #FF00FF"
                        p="11px 51px"
                    >
                        STAKED
                    </Tab>
                    <Tab
                        color="#fff"
                        _selected={{ bg: "primary" }}
                        fontWeight={500}
                        fontSize={{ base: "16px", md: "26px" }}
                        lineHeight='23px'
                        border="1px solid #FF00FF"
                        p="11px 51px"
                    >
                        REWARDS
                    </Tab>
                </TabList>
            </VStack>
        )
    }

    return (
        <Box w="full">
            <Tabs variant='unstyled' >

                {width <= 820 ?
                    <VStack alignSelf="start" w="full">
                        <Image src={TextHistoryImage} alignSelf="start" />
                        {renderTablist()}
                    </VStack>
                    :
                    <HStack w="full" justifyContent="space-between">
                        <Image src={TextHistoryImage} />
                        {renderTablist()}
                    </HStack>
                }

                <VStack
                    style={{
                        borderImageSource: "linear-gradient(226.02deg, #E61CFF 0.76%, rgba(245, 28, 250, 0) 30.73%, rgba(255, 28, 247, 0) 60.97%, #FF1CF7 100%)",
                        // filter: "blur(2px)"
                    }}
                    borderWidth="1px"
                    borderStyle="solid"
                    borderColor="primary"
                    borderRadius="2px"
                    background="rgba(37, 13, 73, 0.2)"
                    boxSizing='border-box'
                    pt="40px"
                    mt="-30px"
                >

                    <TabPanels>
                        <TabPanel>
                            {renderTab()}
                        </TabPanel>
                        <TabPanel>
                            {/* {renderTab()} */}
                        </TabPanel>
                    </TabPanels>

                </VStack>
            </Tabs>

        </Box>
    )
}
