
import { useToast } from "@chakra-ui/react";
export const useAlert = () => {
    const toast = useToast();
    const handleErrorBlockChain = (error: any) => {
        const message = error?.data?.message ?? error?.message ?? "Unknown"
        toast({
            title: message,
            position: "top-right",
            isClosable: true,
            status: "error",
        });
    }

    const success = (message: string = "Done", description = "") => {
        toast({
            title: message,
            description: description,
            position: "top-right",
            isClosable: true,
            status: "success",
        });
    }

    const error = (message: string = "Error", description = "") => {
        toast({
            title: message,
            description: description,
            position: "top-right",
            isClosable: true,
            status: "error",
        });
    }

    return {
        handleErrorBlockChain,
        success,
        error
    }
}