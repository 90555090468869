import { routers } from "./Router";
import { ReactComponent as HomeIcon } from '~/assets/svgs/header/home.svg'
import { ReactComponent as TokenIcon } from '~/assets/svgs/header/tokenomic.svg'
import { ReactComponent as StakingIcon } from '~/assets/svgs/header/staking.svg'
import { ReactComponent as VestingIcon } from '~/assets/svgs/header/vesting.svg'
import { ReactComponent as MintIcon } from '~/assets/svgs/header/mint.svg';
import { ReactComponent as InfoIcon } from '~/assets/svgs/info-icon.svg';


import SwapIcon from '~/assets/images/swap.png';

export const MainLinks = [
    {
        title: 'Home',
        key: 'home',
        href: '/',
        icon: HomeIcon,
    },
    {
        title: 'Affiliate',
        key: 'affiliate',
        href: `/` + routers.affiliate,
        icon: MintIcon,
    },
    {
        title: 'Swap Nft',
        key: 'swapNft',
        href: `/` + routers.swap,
        icon: SwapIcon,
    },
    {
        title: 'Link Account',
        key: 'linkAccountApp',
        href: `/` + routers.linkAccountApp,
        icon: InfoIcon,
    },
    {
        title: 'Marketplace',
        key: 'marketplace',
        href: `/` + routers.marketplace,
        icon: MintIcon,
    },

    {
        title: 'Staking',
        key: 'staking',
        href: `/` + routers.staking,
        icon: StakingIcon,
    },
    {
        title: 'Vesting',
        key: 'vesting',
        href: `/` + routers.vesting,
        icon: VestingIcon,
    },
    {
        title: 'Tokenomics',
        key: 'tokenomics',
        href: `/` + routers.tokenomics,
        icon: TokenIcon,
    },

]