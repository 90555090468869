import { HStack, Icon, Link, } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

const NavLinkItem = ({ title, icon, href, onClose }: any) => {
  const history = useNavigate()

  return (
    <HStack align="center">
      {icon && <Icon as={icon} w="25px" height="25px" />}
      <Link
        onClick={() => {
          history(href ?? '#')
          onClose()
        }}
        fontWeight={600}
        _hover={{
          textDecoration: 'none',
        }}
        color={"secondary"}
        fontSize="16px"
      >
        {title}
      </Link>
    </HStack>
  )
}

export default NavLinkItem