import { BigNumber, Contract } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { formatAmountToken } from "~/@helpers/block-chain.helper";
import { formatMoney } from "~/@helpers/format.helper";
import ERC20__factory from "~/common/abis/ERC20__factory";
import { useConnectWallet } from "./useConnectWallet";




export const useBalanceToken = (props: { tokenAddress: string; decimal?: number }) => {
    const { tokenAddress, decimal = 18 } = props;
    const { library, account } = useConnectWallet();
    const [balance, setBalance] = useState(0);

    const loadBalance = useCallback(async () => {
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const tokenCt = new Contract(
                tokenAddress,
                ERC20__factory.abi,
                library
            )
            const data: BigNumber = await tokenCt.balanceOf(account);
            setBalance(Number(formatAmountToken(data, decimal)))

        } catch (error) {
        }
    }, [account, decimal, library, tokenAddress]);


    useEffect(() => {
        loadBalance();
    }, [loadBalance])


    return {
        balance: formatMoney(balance, 2)
    }
}