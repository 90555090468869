import { Box, HStack, Image, Tooltip, } from '@chakra-ui/react'

const SOCIALS = [
    {
        title: "Twitter",
        icon: "logo-twitter",
        href: ""
    },
    {
        title: "Discord",
        icon: "logo-discord",
        href: ""
    },
    {
        title: "Telegram",
        icon: "logo-telegram",
        href: ""
    },
    {
        title: "Medium",
        icon: "logo-medium",
        href: ""
    },
    {
        title: "Facebook",
        icon: "logo-facebook",
        href: ""
    },
    {
        title: "Youtube",
        icon: "logo-youtube",
        href: ""
    },
]

export const SocialMoverse = () => {
    return (
        <HStack spacing={6}>
            {SOCIALS.map((item, idx) => (
                <Tooltip
                    label={item.title}
                    placement='top-start'
                    color={"#fff"}
                    borderRadius="8px"
                    key={idx}
                >
                    <Box
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            if (item.href) {
                                window.open(item.href, '_blank')
                            }
                        }}
                    >
                        <Image
                            src={`/logo-socials/${item.icon}.svg`}
                            h={{ base: "24px", md: "36px" }}
                            w={{ base: "24px", md: "36px" }}
                        />
                    </Box>
                </Tooltip>
            ))}
        </HStack>
    )
}
