

import dayjs from 'dayjs'
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const formatNumberToSymbol = (value: number) => {
    if (value < 1000) {
        return value.toString()
    }
    const formatter = Intl.NumberFormat('en', { notation: 'compact' });
    return formatter.format(value)
}

export const msToUTCString = (value: number, dateFormat: string = "DD/MM/YYYY HH:mm:ss") => {
    return dayjs(value).utc().format(dateFormat);
}


export const formatMoney = (valueNumber: number, decimalPlaces?: number) => {
    let value = valueNumber.toFixed(decimalPlaces || 0);
    if (!value) {
        return "";
    }
    value = value
        // ?.replace(/\D/g, "")
        // .replace(/\./, "")
        .replace(/\,/g, "") ?? "0"
    const arrStr = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,").split(".");
    if (arrStr.length < 2) {
        return arrStr[0];
    }
    return `${arrStr[0]}.${arrStr[1].replace(/\,/g, "")}`

}


export const formatMoneyInput = (value: string | undefined) => {
    if (!value) {
        return "";
    }
    value = value
        // ?.replace(/\D/g, "")
        // .replace(/\./, "")
        .replace(/\,/g, "") ?? "0"
    const arrStr = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,").split(".");

    if (arrStr.length < 2) {
        return arrStr[0];
    }
    return `${arrStr[0]}.${arrStr[1].replace(/\,/g, "")}`

}


export const pipeAddressUi = (address: string) => {
    if (!address) {
        return "";
    }
    if (address.length <= 8) {
        return address;
    }
    return `${address.substring(0, 4)}...${address.substring(address.length - 4)}`
}
