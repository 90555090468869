import { Box, Text, HStack, VStack, Button, SimpleGrid } from '@chakra-ui/react'
import { useFaucetTestnet, usePartnerClaimNft } from '~/hooks/faucet-testnet'
const ItemRowInfo = (props: { title: string, value: string | number }) => {
    const { title, value } = props;
    return (
        <HStack
            justifyContent="space-between"
            w="full"
            pb="8px"
            borderBottom="0.949367px solid #6B6785"
        >
            <Text
                fontWeight="400"
                fontSize="15px"
                lineHeight="19px"
                color="#fff"
                textAlign="left"
            >
                {title}
            </Text>
            <Text
                fontWeight="400"
                fontSize="15px"
                lineHeight="19px"
                color="#fff"
                textAlign="right"
            >
                {value}
            </Text>
        </HStack>
    )
}


export const HomeView = () => {

    const { isLoading: isLoadingFaucet, granted: grantedFaucet, faucet, } = useFaucetTestnet();
    const { isLoading, granted, claim, totalAllocation, totalClaimed } = usePartnerClaimNft();

    return (
        <Box>
            <Box h={"15vh"} />
            <VStack
                bg="transparent"
                w="full"
                // mt={{ base: "50px", md: "100px" }}
                mb="90px"
                px={{
                    base: "10px",
                    md: "0px"
                }}
            >
                <Text
                    color="#D917E2"
                    fontWeight="700"
                    fontSize={{ base: "33px", md: "35px" }}
                    lineHeight={{ base: "40px", md: "44px" }}
                //textTransform="uppercase"
                >
                    Moverse Faucet
                </Text>

            </VStack>

            <SimpleGrid
                columns={{ base: 1, md: 2, xl: 2 }}
                spacing={4}
            >
                <Box
                    cursor="pointer"
                    borderColor="#6B6785"
                    borderWidth="0.949367px"
                    borderStyle="solid"
                    background="rgba(26, 0, 60, 0.3)"
                    borderRadius="12px"
                    p={{ base: "15px", md: "45px" }}
                >
                    <Text
                        fontWeight="400"
                        fontSize="30px"
                        lineHeight="19px"
                        color="primary"
                        textAlign="left"
                    >
                        {"Faucet F0 NFT shoes"}
                    </Text>
                    <VStack mt="20px">
                        <ItemRowInfo title='Claimed/Total' value={`${totalClaimed}/${totalAllocation}`} />
                    </VStack>

                    <VStack mt="20px">
                        <Button
                            background="linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)"
                            _focus={{
                                backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
                            }}
                            _hover={{
                                backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
                            }}
                            padding="7.59494px 30.3797px"
                            borderRadius="5px"
                            color="#fff"
                            w="244px"
                            disabled={!granted}
                            isLoading={isLoading}
                            onClick={claim}
                        >
                            Claim NFT
                        </Button>

                    </VStack>
                </Box>
                <Box
                    cursor="pointer"
                    borderColor="#6B6785"
                    borderWidth="0.949367px"
                    borderStyle="solid"
                    background="rgba(26, 0, 60, 0.3)"
                    borderRadius="12px"
                    p={{ base: "15px", md: "45px" }}
                >
                    <Text
                        fontWeight="400"
                        fontSize="30px"
                        lineHeight="19px"
                        color="primary"
                        textAlign="left"
                    >
                        {"Faucet TBUSD token daily"}
                    </Text>
                    <VStack mt="20px">
                        <ItemRowInfo title='Amount daily' value={'200 TBUSD'} />
                    </VStack>

                    <VStack mt="20px">
                        <Button
                            background="linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)"
                            _focus={{
                                backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
                            }}
                            _hover={{
                                backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
                            }}
                            padding="7.59494px 30.3797px"
                            borderRadius="5px"
                            color="#fff"
                            w="244px"
                            disabled={!grantedFaucet}
                            isLoading={isLoadingFaucet}
                            onClick={faucet}
                        >
                            Claim Token
                        </Button>
                    </VStack>
                </Box>
            </SimpleGrid>



            <Box h={{ base: "10vh", lg: "50vh" }} />

        </Box>
    )
}
