import { useCallback, useEffect, useState } from "react"
import { compareAddress } from "~/@helpers/block-chain.helper";
import { LocalStoreKey } from "~/common/constants/LocalStoreKey"
import moverseAppService from "~/services/moverse-app.service"
import { useAlert, useConnectWallet } from "../@global";



export const useLinkAccount = () => {
    const { account } = useConnectWallet();
    const toast = useAlert();
    const [isLoading, setIsLoading] = useState(false);



    const [userInfo, setUserInfo] = useState<{
        id: string;
        accessToken: string;
        refId: string;
        name: string;
        walletAddress: string;
    }>({
        // ...JSON.parse(localStorage.getItem(LocalStoreKey.userInfoApp))
        id: "",
        accessToken: "",
        refId: "",
        name: "",
        walletAddress: ""
    })

    const loadUserInfo = useCallback(async () => {
        setIsLoading(true);
        try {
            const { id } = JSON.parse(localStorage.getItem(LocalStoreKey.userInfoApp));
            const { accessToken, refId, name, walletAddress } = await moverseAppService.userDetail({ id });
            localStorage.setItem(LocalStoreKey.userInfoApp, JSON.stringify({
                accessToken,
                id,
                name
            }))
            setUserInfo({
                accessToken,
                refId,
                id,
                name,
                walletAddress
            })

        } catch (error) {


        }
        setIsLoading(false);
    }, []);


    useEffect(() => { loadUserInfo() }, [loadUserInfo])

    const login = useCallback(async (email: string, password: string, reloadData?: () => Promise<void>) => {
        setIsLoading(true);
        try {
            const { accessToken, refId, _id: id, name, walletAddress } = await moverseAppService.login({ email, password });
            localStorage.setItem(LocalStoreKey.userInfoApp, JSON.stringify({
                accessToken,
                id,
                name
            }))
            setUserInfo({
                accessToken,
                refId,
                id,
                name,
                walletAddress
            })
            await reloadData();
        } catch (error) {
            localStorage.removeItem(LocalStoreKey.userInfoApp);
            toast.error(error?.message || "Unknown")
        }
        setIsLoading(false);
    }, [])


    const linkAccount = useCallback(async (reloadData?: () => Promise<void>) => {
        setIsLoading(true);
        try {
            const { refId } = userInfo;
            await moverseAppService.syncWalletAddressByUser({
                userRefId: refId,
                walletAddress: account
            });
            if (reloadData) {
                await reloadData();
            }
            await loadUserInfo();
        } catch (error) {
            toast.error(error?.message || "Unknown")
        }
        setIsLoading(false);
    }, [account, loadUserInfo, userInfo])

    return {
        isLoading,
        ...userInfo,
        isLinked: compareAddress(account, userInfo.walletAddress),
        login,
        linkAccount
    }
}