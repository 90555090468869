import { useCallback } from 'react'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Button,
    Text,
    HStack,
    Image,
    VStack,
    Box,
    Icon,
    Divider,
} from '@chakra-ui/react'
import { ReactComponent as VerifyIcon } from '~/assets/svgs/components/verify.svg'
import { formatMoney } from '~/@helpers/format.helper';
import { useMarketCollection } from '~/hooks/market';
import ConnectWalletButton from '~/layouts/ConnectWalletButton';
import { useConnectWallet } from '~/hooks/@global';
import { IMyOrderItemProps } from './MyOrderItem';
interface ICancelListingNftModal extends IMyOrderItemProps {
    isOpen?: boolean;
    onDismiss?: () => any;
}

export const CancelListingNftModal: React.FC<ICancelListingNftModal> = (props: ICancelListingNftModal) => {

    const { onDismiss: onClose = () => {
        return null
    }, isOpen = false, price: inputPrice, loadData, askId } = props;
    const { account } = useConnectWallet();
    const { askCancelListing, isLoading } = useMarketCollection();
    const onClickAskCancelListing = useCallback(async () => {
        await askCancelListing(
            askId,
            async () => {
                await loadData();
                onClose();
            }
        )
    }, [askCancelListing, askId, loadData, onClose])

    const renderBtnSell = useCallback(() => {
        if (!account) {
            return <ConnectWalletButton />
        }

        return (
            <Button
                background="linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)"
                _focus={{
                    backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
                }}
                _hover={{
                    backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
                }}
                padding="7.59494px 30.3797px"
                borderRadius="5px"
                color="#fff"
                w="full"
                isLoading={isLoading}
                onClick={onClickAskCancelListing}
            >
                Cancel Listing
            </Button>
        )
    }, [account, isLoading, onClickAskCancelListing])

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent maxW="729px" mx="20px">
                    <ModalHeader
                        fontSize="18px"
                        fontWeight="500"
                        letterSpacing="-0.5px"
                        lineHeight="21px"
                        px={{
                            base: '10px',
                            lg: '20px',
                        }}
                        py="12px"
                    >
                        CANCEL LISTING
                    </ModalHeader>
                    <ModalCloseButton />
                    <Divider />
                    <ModalBody
                        px={{
                            base: '20px',
                            lg: '40px',
                        }}
                        py="30px"
                    >

                        <Text
                            fontWeight="400"
                            fontSize="18px"
                            lineHeight="20px"
                            color="grey.66"
                            letterSpacing="-0.5px"
                        >
                            List Price
                        </Text>
                        <Text
                            mt="10px"
                            fontSize="18px"
                            fontWeight="600"
                            letterSpacing="-0.5px"
                            lineHeight="20px"
                            color="primary"
                            textTransform="uppercase"
                        >
                            {formatMoney(inputPrice)} MOVE
                        </Text>
                        <Text
                            mt="26px"
                            fontWeight="500"
                            fontSize="16px"
                            lineHeight="24px"
                            color="black.light"
                        >
                            Listing is FREE! When the sale succeeds, the following fees will
                            be charged.
                        </Text>

                        <HStack mt="10px">
                            <Text
                                fontWeight="400"
                                fontSize="15px"
                                lineHeight="18px"
                                color="black.light"
                                w="141px"
                                letterSpacing="-0.5px"
                            >
                                Transaction Fee
                            </Text>
                            <Text
                                fontWeight="400"
                                fontSize="15px"
                                lineHeight="18px"
                                color="black.light"
                            >
                                2%
                            </Text>
                        </HStack>

                        <HStack mt="10px">
                            <Text
                                fontWeight="400"
                                fontSize="15px"
                                lineHeight="18px"
                                color="black.light"
                                w="141px"
                                letterSpacing="-0.5px"
                            >
                                Listing/ Cancel/ Fee
                            </Text>
                            <Text
                                fontWeight="500"
                                fontSize="15px"
                                lineHeight="18px"
                                color="blue.neutral"
                            >
                                FREE
                            </Text>
                        </HStack>

                        <HStack alignItems="flex-start" mt="40px">
                            <Image
                                w="100px"
                                h="100px"
                                src={props?.image}
                                fallbackSrc="/assets/images/empty-item.png"
                                objectFit={'contain'}
                                borderRadius="10px"
                                background="white"
                                filter="drop-shadow(-3px 3px 8px rgba(0, 0, 0, 0.06)) drop-shadow(3px 3px 8px rgba(0, 0, 0, 0.06))"
                            />
                            <VStack alignItems="flex-start">
                                <HStack alignItems="center">
                                    <Text
                                        fontSize="15px"
                                        lineHeight="18px"
                                        letterSpacing="-0.5px"
                                        color="grey.66"
                                    >
                                        {props?.name}
                                    </Text>
                                    <Icon as={VerifyIcon} />
                                </HStack>

                                <Text
                                    mt="12px"
                                    fontSize="18px"
                                    lineHeight="21px"
                                    fontWeight="600"
                                    color="black.1d"
                                    letterSpacing="-0.5px"
                                >
                                    {props?.name} #{props?.tokenId}
                                </Text>
                            </VStack>
                        </HStack>
                    </ModalBody>

                    <ModalFooter w="100%" mt="30px">
                        <HStack flex={1} w="100%">
                            <Box flex={1}>{renderBtnSell()}</Box>
                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
};

