import { Button } from '@chakra-ui/react'
import { useConnectWallet, useWalletModal } from '~/hooks/@global'


const ConnectWalletButton = () => {
  const { login } = useConnectWallet()
  const { onPresentConnectModal } = useWalletModal(login)

  return (
    <Button
      minW="120px"
      w="full"
      color="#fff"
      fontSize="16px"
      _focus={{
        backgroundColor: 'linear-gradient(91.61deg, #E708E4 -3.84%, #25BEC8 100%)',
      }}
      _hover={{
        backgroundColor: 'linear-gradient(91.61deg, #E708E4 -3.84%, #25BEC8 100%)',
      }}
      background="linear-gradient(91.61deg, #E708E4 -3.84%, #25BEC8 100%)"
      onClick={onPresentConnectModal}
    >
      Connect Wallet
    </Button>
  )
}

export default ConnectWalletButton
