import { IEnvConfig } from '..';

const config: IEnvConfig = {
  name: "PRODUCTION",
  DOMAIN_URL: "https://dapp.moverse.biz",
  CONNECTORS: {
    ROOT: {
      baseUrl: "https://dapp-api.moverse.biz"
    },
    MOVERSE_APP_API: {
      baseUrl: "https://app.moverse.biz/api/prod",
      "mvs-service-account-id": "636e008cf214372e7b22bfc0",
      "mvs-service-account-secret": "ldTmMX7G6$$mebC@cSIB6e5Mo$Hjo6Bo",
      "api-key": "mKLxLfCwr4lnoXV25r9d&T2!"
    },
  },
  DEFAULT_CHAIN_CODE: "POLYGON",
  CHAINS: {
    POLYGON: {
      chainId: 137,
      chainName: "Polygon",
      nativeCurrency: {
        "name": "MATIC",
        "symbol": "MATIC",
        "decimals": 18
      },
      rpcUrls: [
        "https://polygon-rpc.com/",
        "https://rpc-mainnet.matic.network",
        "https://matic-mainnet.chainstacklabs.com",
        "https://rpc-mainnet.maticvigil.com",
        "https://rpc-mainnet.matic.quiknode.pro",
        "https://matic-mainnet-full-rpc.bwarelabs.com"
      ],
      blockExplorerUrls: ["https://polygonscan.com"],
    }
  },
  ADDRESS_CONFIG: {
    WBNB: "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c".trim(),
    BUSD: "0xe9e7cea3dedca5984780bafc599bd69add087d56".trim(),
    DEX_FACTORY: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73".trim(), //pancakeswap
    DEX_ROUTER: "0x10ED43C718714eb63d5aA57B78B54704E256024E".trim(), // pancakeswap

    SNEAKER_BOX: "",
    SNEAKER: "",
    POOL_STAKING_FIXED: "",
    POOL_STAKING_AUTO_APR: "",
    POOL_VESTING_PRIVATE: "",
    POOL_VESTING_PARTNER: "",
    POOL_VESTING_AIRDROP: "",
    MOVE: "".trim(),
    MOVERSE_BOX: "".trim(),
    MOVERSE_NFT: "".trim(),
    POO_AFF: "".trim(),
    POOL_LIQ_NFT: "".trim(),
    MARKET_ADDRESS: "".trim(),
    PARTNER_CLAIM_NFT: "".trim(),
  },
}

export default config;
