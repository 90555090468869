



import { BigNumber, Contract } from "ethers";
import { useCallback, useEffect, useState } from "react"
import { configEnv } from "~/@config";
import MoverseBox__factory from "~/common/abis/MoverseBox__factory";
import MoverseNft__factory from "~/common/abis/MoverseNft__factory";
import ChainConfig from "~/common/constants/ChainConfig";
import { useAlert, useConnectWallet } from "../@global";

const { MOVERSE_BOX, MOVERSE_NFT,POO_AFF } = configEnv().ADDRESS_CONFIG;

export const useInventory = () => {

    const toast = useAlert();
    const { library, account } = useConnectWallet();

    const [isLoading, setLoading] = useState(false);
    const [listBox, setListBox] = useState<{ tokenId: number }[]>([]);
    const [listNft, setListNft] = useState<{ tokenId: number }[]>([]);

    const loadDataNft = useCallback(async () => {
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const nftCt = new Contract(
                MOVERSE_NFT,
                MoverseNft__factory.abi,
                library
            )
            const list: BigNumber[] = await nftCt.tokenIdsOfOwner(account);
            console.log(`-------------------`);
            console.log(list);
            console.log(`-------------------`);
            setListNft(list.map(tokenId => ({
                tokenId: tokenId.toNumber()
            })))
        } catch (error) {
            console.log(`=====loadData=====`, error);
        }
    }, [account, library]);

    const loadDataBox = useCallback(async () => {
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const boxCt = new Contract(
                MOVERSE_BOX,
                MoverseBox__factory.abi,
                library
            )
            const list: BigNumber[] = await boxCt.tokenIdsOfOwner(account);
            setListBox(list.map(tokenId => ({
                tokenId: tokenId.toNumber()
            })))
        } catch (error) {
            console.log(`=====loadData=====`, error);
        }
    }, [account, library]);


    useEffect(() => {
        loadDataNft()
    }, [loadDataNft])




    useEffect(() => {
        loadDataBox()
    }, [loadDataBox]);

    const openBox = useCallback(async (boxId: number) => {
        setLoading(true);
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const sender = library.getSigner(account);
            const boxCt = new Contract(
                MOVERSE_BOX,
                MoverseBox__factory.abi,
                library
            )
            const { transactionHash } = await (
                await (boxCt.connect(sender).openBox(boxId))
            ).wait();

            await Promise.all([
                loadDataBox(),
                loadDataNft()
            ])
            toast.success(`${ChainConfig.getUrlScan()}/tx/${transactionHash}`);

        } catch (error) {
            toast.handleErrorBlockChain(error)
        }
        setLoading(false);
    }, [account, library, loadDataBox, loadDataNft, toast]);


    return {
        listBox,
        listNft,
        isLoading,
        openBox
    }

}