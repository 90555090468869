import React, { useCallback, } from "react";
import {
  ModalOverlay,
  Modal,
  ModalContent,
  ModalCloseButton,
  Text,
  Button,
  VStack,
  ModalBody,
} from "@chakra-ui/react";
import { ReactTree } from '@naisutech/react-tree'
import { useAffTree } from "~/hooks/inventory/useAffTree";
interface IAffTreeModal {
  isOpen?: boolean;
  onDismiss?: () => any;
  nftId: number;
}

const AffTreeModal: React.FC<IAffTreeModal> = (props: IAffTreeModal) => {

  const { onDismiss: onClose = () => {
    return null
  }, isOpen = false, nftId } = props;

  const { isLoading, flatTree } = useAffTree({ nftId });


  const renderContent = useCallback(() => {
    return (
      <ReactTree
        containerStyles={{
          width: "100%",
          // background: "#D917E2"
        }}
        nodes={flatTree}
        loading={isLoading}
      // theme="dark"
      />
    )
  }, [flatTree, isLoading])


  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        maxWidth={{
          base: "fit-content",
          lg: "200px",
          xl: "200px",
          '2xl': "400px",
        }}
        w={{
          base: "fit-content",
          lg: "600px",
          xl: "600px",
          '2xl': "800px",
        }}
      >
        <ModalCloseButton color="#fff" />
        <ModalBody
          boxShadow="0px 0px 24px #4A0061"
        // background="secondary"
        >
          <VStack p="20px">
            <Text
              color="#D917E2"
              fontWeight="700"
              fontSize={{ base: "20px", md: "22px" }}
              lineHeight={{ base: "40px", md: "44px" }}
              textTransform="uppercase"
            >
              Tree nft ref
            </Text>
            <Text
              color="#D917E2"
              fontWeight="700"
              textTransform="uppercase"
            >

            </Text>
            {renderContent()}
            <Button
              bg="#FF5252"
              color="#fff"

              borderRadius="4px"
              fontSize="16px"
              _focus={{
                backgroundColor: "#FF5252",
              }}
              _hover={{
                backgroundColor: "#FF5252",
              }}
              _active={{
                backgroundColor: "#FF5252",
              }}
              onClick={onClose}
            >
              Close
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AffTreeModal;
