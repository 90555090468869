import { Box, Text, VStack, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { useWindowSize } from '~/hooks/@global'
import useCopyToClipboard from '~/hooks/@common/useCopyToClipboard'
import { configEnv } from '~/@config'
import { BuyView } from './BuyView'
import { SellView } from './SellView'


const { DOMAIN_URL } = configEnv();




export const SwapView = () => {
    const { width } = useWindowSize();
    const navigate = useNavigate();

    const [value, copy] = useCopyToClipboard();




    return (
        <Box>
            <Box h={"15vh"} />
            <VStack
                bg="transparent"
                w="full"
                // mt={{ base: "50px", md: "100px" }}
                // mb="90px"
                px={{
                    base: "10px",
                    md: "0px"
                }}
            >
                <Text
                    color="#D917E2"
                    fontWeight="700"
                    fontSize={{ base: "33px", md: "35px" }}
                    lineHeight={{ base: "40px", md: "44px" }}
                    textTransform="uppercase"
                >
                    MOVERSE SWAP NFT
                </Text>

            </VStack>

            <Tabs variant='unstyled' pt={{ base: "0px", md: "25px" }} isFitted>
                <VStack>
                    <TabList>
                        <Tab
                            color="#fff"
                            _selected={{ bg: "primary" }}
                            fontWeight={500}
                            fontSize={{ base: "16px", md: "26px" }}
                            lineHeight='23px'
                            border="1px solid #FF00FF"
                            p={{ base: "5px", lg: "11px 51px" }}
                        >
                            BUY
                        </Tab>
                        <Tab
                            color="#fff"
                            _selected={{ bg: "primary" }}
                            fontWeight={500}
                            fontSize={{ base: "16px", md: "26px" }}
                            lineHeight='23px'
                            border="1px solid #FF00FF"
                            p={{ base: "5px", lg: "11px 51px" }}
                        >
                            SELL
                        </Tab>
                    </TabList>
                </VStack>

                <TabPanels>
                    <TabPanel>
                        <BuyView />
                    </TabPanel>
                    <TabPanel>
                        <SellView />
                    </TabPanel>
                </TabPanels>
            </Tabs>

            <Box h={{ base: "10vh", lg: "50vh" }} />

        </Box>
    )
}
