import * as envs from './envs';



export interface IChainConfig {
  chainId: number;
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string;
    decimals: number;
  };
  rpcUrls: string[];
  blockExplorerUrls: string[];
}

export interface IChainList {
  POLYGON: IChainConfig;
}

export type ICodeChainList = keyof IChainList;

export interface IEnvConfig {
  name?: string,
  DOMAIN_URL: string,
  CONNECTORS: {
    ROOT: {
      baseUrl: string,
    },
    MOVERSE_APP_API: {
      baseUrl: string;
      "mvs-service-account-id": string;
      "mvs-service-account-secret": string;
      "api-key": string;
    }
  },
  DEFAULT_CHAIN_CODE: ICodeChainList;
  CHAINS: IChainList;
  ADDRESS_CONFIG: {
    WBNB: string;
    BUSD: string;
    DEX_FACTORY: string;
    DEX_ROUTER: string;
    SNEAKER_BOX: string;
    SNEAKER: string;
    POOL_STAKING_FIXED: string;
    POOL_STAKING_AUTO_APR: string;
    POOL_VESTING_PRIVATE: string;
    POOL_VESTING_PARTNER: string;
    POOL_VESTING_AIRDROP: string;
    MOVE: string;
    MOVERSE_BOX: string;
    MOVERSE_NFT: string;
    POO_AFF: string;
    POOL_LIQ_NFT: string;
    MARKET_ADDRESS: string;
    PARTNER_CLAIM_NFT: string;
  },
}
let envConfig: IEnvConfig;
export function configEnv(): IEnvConfig {
  if (envConfig) {
    return envConfig;
  }
  const envName = process.env.REACT_APP_ENV || 'qa';
  const currentConfig = (envs as any)[envName];
  return {
    ...currentConfig,
    name: envName
  }
}



