import { IEnvConfig } from '..';

const config: IEnvConfig = {
  name: "DEV",
  DOMAIN_URL: "https://dapp.moverse.biz",
  CONNECTORS: {
    ROOT: {
      baseUrl: "https://dapp.moverse.biz/api",
    },
    MOVERSE_APP_API: {
      baseUrl: "https://app.moverse.biz/api/prod",
      "mvs-service-account-id": "636e008cf214372e7b22bfc0",
      "mvs-service-account-secret": "ldTmMX7G6$$mebC@cSIB6e5Mo$Hjo6Bo",
      "api-key": "mKLxLfCwr4lnoXV25r9d&T2!"
    },
  },
  DEFAULT_CHAIN_CODE: "POLYGON",
  CHAINS: {
    POLYGON: {
      // chainId: 80001,
      // chainName: "Polygon Mumbai",
      // nativeCurrency: {
      //   "name": "MATIC",
      //   "symbol": "MATIC",
      //   "decimals": 18
      // },
      // rpcUrls: [
      //   "https://matic-mumbai.chainstacklabs.com",
      //   "https://rpc-mumbai.maticvigil.com",
      //   "https://matic-testnet-archive-rpc.bwarelabs.com"
      // ],
      // blockExplorerUrls: ["https://mumbai.polygonscan.com"],
      chainId: 97,
      chainName: "bsc_testnet",
      nativeCurrency: {
        name: 'BNB',
        symbol: 'bnb',
        decimals: 18
      },
      rpcUrls: [
        "https://data-seed-prebsc-2-s1.binance.org:8545",
        "https://data-seed-prebsc-2-s1.binance.org:8545",
        "https://data-seed-prebsc-2-s1.binance.org:8545"
      ],
      blockExplorerUrls: ["https://testnet.bscscan.com"],
    }
  },
  ADDRESS_CONFIG: {
    WBNB: "0x0dE8FCAE8421fc79B29adE9ffF97854a424Cad09".trim(),
    //  BUSD: "0xe0dfffc2e01a7f051069649ad4eb3f518430b6a4".trim(),
    DEX_FACTORY: "0x5Fe5cC0122403f06abE2A75DBba1860Edb762985".trim(),
    DEX_ROUTER: "0xCc7aDc94F3D80127849D2b41b6439b7CF1eB4Ae0".trim(),
    SNEAKER_BOX: "0x09E39756ACFAd2C7e2398a2dBb9362825F46985D".trim(),
    SNEAKER: "",
    POOL_STAKING_FIXED: "0x969D60605080E38D8fC25da1D10FbBd02d922Cb8",
    POOL_STAKING_AUTO_APR: "0x402f0F7d093a54bF0b8d0d3c463a7a37CbC0e8B7",
    POOL_VESTING_PRIVATE: "",
    POOL_VESTING_PARTNER: "",
    POOL_VESTING_AIRDROP: "",
    BUSD: "0x13b123a205b607Daa99a9D8E21E92d05ad4dFB9F".trim(),
    MOVE: "0x86D66F7d19388eeF25583bEfBfe0d5A33dE22150".trim(),
    MOVERSE_BOX: "0x6D6ecf0E765b4018E845A6F7148d52Dd693Cf060".trim(),
    MOVERSE_NFT: "0x50afbDa32E1a033580082C09a8B7De844e5fc015".trim(),
    POOL_LIQ_NFT: "0x79B2BB7F09eFC6e838825D0865b9Ed4f25C85c6C".trim(),
    POO_AFF: "0xaa1a9124a6C2c7697830Bf4f9dbC09DfaED0FFf1".trim(),
    MARKET_ADDRESS: "0xd4006ad7df359eB40558885B6a97329DafF151e4".trim(),
    PARTNER_CLAIM_NFT: "0x962f409866B1f28cB2B3091d336b54bb749bb24c".trim(),
  },
}

export default config;
