import { BigNumber, constants, Contract } from "ethers";
import { useCallback, useEffect, useState } from "react"
import { configEnv } from "~/@config";
import ERC20__factory from "~/common/abis/ERC20__factory";
import MoverseBox__factory from "~/common/abis/MoverseBox__factory";
import PoolAff__factory from "~/common/abis/PoolAff__factory";
import ChainConfig from "~/common/constants/ChainConfig";
import { useAlert, useConnectWallet } from "../@global";

const { MOVERSE_BOX, MOVERSE_NFT, POO_AFF, BUSD } = configEnv().ADDRESS_CONFIG;



export const useAffTree = ({ nftId }) => {


    const { library, account } = useConnectWallet();

    console.log(`-------------------`);
    console.log({ nftId });
    console.log(`-------------------`);

    const [isLoading, setLoading] = useState(false);
    const [flatTree, setFlatTree] = useState<{ tokenId: number; parentId: number }[]>([]);

    const loadData = useCallback(async () => {
        setLoading(true);
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const poolCt = new Contract(
                POO_AFF,
                PoolAff__factory.abi,
                library
            )

            const { data, total } = await poolCt.viewNftIdsManageOfPool(1, 100) as { data: BigNumber[], total: BigNumber };
            const promises = [];
            for (const tokenId of data.map(v => v.toNumber())) {
                promises.push(poolCt.childrensNft(tokenId).then(parentId => ({
                    tokenId,
                    parentId: parentId.toNumber()
                })))

            }
            const list = await Promise.all(promises);

            console.log(`-------------------`);
            console.log({ list });
            console.log(`-------------------`);

            const filterTree = (parentId: number) => {
                let outputs = [];
                const items = list.filter(v => v.parentId === parentId);
                if (items.length === 0) {
                    return outputs;
                }
                for (const item of items) {
                    outputs.push(item);
                    const res = filterTree(item.tokenId);
                    if (res.length > 0) {
                        outputs = outputs.concat(res);
                    }

                }
                return outputs;
            }
            const dataRes = filterTree(nftId);
            setFlatTree([{
                tokenId: nftId, parentId: null
            }, ...dataRes]);

        } catch (error) {
        }
        setLoading(false);


    }, [account, library, nftId]);



    useEffect(() => {
        loadData();
    }, [loadData]);

    return {
        isLoading,
        flatTree: flatTree.map(({ tokenId, parentId }) => ({
            id: tokenId,
            parentId,
            label: `Moverse shoes #${tokenId}`
        }))
    }
}