

import { BigNumber, Contract } from "ethers";
import { useCallback, useEffect, useState } from "react"
import { configEnv } from "~/@config";
import { formatAmountToken } from "~/@helpers/block-chain.helper";
import MoverseBox__factory from "~/common/abis/MoverseBox__factory";
import MoverseNft__factory from "~/common/abis/MoverseNft__factory";
import PoolAff__factory from "~/common/abis/PoolAff__factory";
import ChainConfig from "~/common/constants/ChainConfig";
import { useAlert, useConnectWallet } from "../@global";

const { MOVERSE_BOX, MOVERSE_NFT, POO_AFF } = configEnv().ADDRESS_CONFIG;



export const useNftItem = ({ nftId }) => {


    const [amount, setAmount] = useState(0);

    const toast = useAlert();
    const { library, account } = useConnectWallet();

    const [isLoading, setLoading] = useState(false);


    const loadData = useCallback(async () => {
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const poolCt = new Contract(
                POO_AFF,
                PoolAff__factory.abi,
                library
            )
            const res: BigNumber = await poolCt.amountPendingRevenueOfNftId(nftId);
            setAmount(Number(formatAmountToken(res)))
        } catch (error) {
            console.log(`=====loadData=====`, error);
        }
    }, [account, library, nftId]);
    useEffect(() => {
        loadData()
    }, [loadData]);


    const claim = useCallback(async () => {
        setLoading(true);
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const sender = library.getSigner(account);
            const poolCt = new Contract(
                POO_AFF,
                PoolAff__factory.abi,
                library
            )
            const { transactionHash } = await (
                await (poolCt.connect(sender).claimRevenueRef(nftId))
            ).wait();
            await Promise.all([
                loadData()
            ])
            toast.success(`${ChainConfig.getUrlScan()}/tx/${transactionHash}`);

        } catch (error) {
            toast.handleErrorBlockChain(error)
        }
        setLoading(false);
    }, [account, library, loadData, nftId, toast]);



    return {
        isLoading,
        amount,
        claim
    }
}