import { Box, Text, HStack, VStack, Button, Tab, TabList, TabPanel, TabPanels, Tabs, Image, SimpleGrid, Icon, Tooltip } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import TextVestingImage from '~/assets/svgs/vesting.png'
import { useWindowSize } from '~/hooks/@global'
import { useInventory } from '~/hooks/inventory'
import useCopyToClipboard from '~/hooks/@common/useCopyToClipboard'
import { ReactComponent as CopyClipboar } from '~/assets/svgs/copy.svg'
import { ReactComponent as OpenLinkIcon } from '~/assets/svgs/open-link-icon.svg'
import { configEnv } from '~/@config'
import { useNftItem } from '~/hooks/inventory/useNftItem'
import { useAffTreeModal } from '~/hooks/inventory/useViewTreeModal'
import { routers } from '~/common/constants/Router'


const { DOMAIN_URL } = configEnv();


const ClaimView = ({ nftId }) => {
    const { amount, isLoading, claim } = useNftItem({ nftId });
    return (
        <Button
            background="linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)"
            _focus={{
                backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
            }}
            _hover={{
                backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
            }}
            padding="7.59494px 30.3797px"
            borderRadius="5px"
            color="#fff"
            w="full"
            disabled={amount <= 0}
            isLoading={isLoading}
            onClick={(e) => {
                e.stopPropagation();
                claim();
            }}
        >
            {amount > 0 ? ` Claim ${amount} BUSD` : "Claim"}
        </Button>
    )
}

const AffTree = ({ nftId }) => {
    const { onPresentViewTreeModal } = useAffTreeModal({ nftId });
    return (
        <Button
            background="linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)"
            _focus={{
                backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
            }}
            _hover={{
                backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
            }}
            padding="7.59494px 30.3797px"
            borderRadius="5px"
            color="#fff"

            onClick={onPresentViewTreeModal}
        >
            View tree ref
        </Button>
    )
}

const ItemRowInfo = (props: { title: string, value: string | number }) => {
    const { title, value } = props;
    return (
        <HStack
            justifyContent="space-between"
            w="full"
            pb="8px"
            borderBottom="0.949367px solid #6B6785"
        >
            <Text
                fontWeight="400"
                fontSize="15px"
                lineHeight="19px"
                color="#fff"
                textAlign="left"
            >
                {title}
            </Text>
            <Text
                fontWeight="400"
                fontSize="15px"
                lineHeight="19px"
                color="#fff"
                textAlign="right"
            >
                {value}
            </Text>
        </HStack>
    )
}

export const InventoryView = () => {
    const { width } = useWindowSize();
    const navigate = useNavigate();

    const [value, copy] = useCopyToClipboard();
    const { listBox, listNft, isLoading, openBox } = useInventory();

    const renderTabBox = () => {
        return (
            <SimpleGrid
                columns={{ base: 1, md: 2, xl: 4 }}
                spacing={4}
            >
                {listBox.map((item, idx) => {
                    return (
                        <Box

                            key={idx}
                            borderColor="#6B6785"
                            borderWidth="0.949367px"
                            borderStyle="solid"
                            background="rgba(26, 0, 60, 0.3)"
                            borderRadius="12px"
                            p={{ base: "5px", md: "10px" }}
                        >
                            <Image src={"https://app.moverse.biz/media/prod/mvs-image-res-01/upload/mvs/res/544acc39-ae21-440b-9178-ab88d9d86cac.png"} />

                            <VStack mt="20px">
                                <ItemRowInfo title='TOKEN ID' value={item.tokenId} />
                            </VStack>

                            <VStack mt="20px">

                                <Button
                                    background="linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)"
                                    _focus={{
                                        backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
                                    }}
                                    _hover={{
                                        backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
                                    }}
                                    padding="7.59494px 30.3797px"
                                    borderRadius="5px"
                                    color="#fff"
                                    w="full"
                                    isLoading={isLoading}
                                    onClick={() => openBox(item.tokenId)}
                                >
                                    OPEN
                                </Button>

                            </VStack>
                        </Box>
                    )
                })}

            </SimpleGrid>
        )
    }
    const renderTabNft = () => {
        return (
            <SimpleGrid
                columns={{ base: 1, md: 2, xl: 4 }}
                spacing={4}
            >
                {listNft.map((item, idx) => {
                    return (
                        <Box
                            key={idx}
                            onClick={(e) => {

                                navigate(`/${routers.inventory}/${item.tokenId}`)
                            }}
                            borderColor="#6B6785"
                            borderWidth="0.949367px"
                            borderStyle="solid"
                            background="rgba(26, 0, 60, 0.3)"
                            borderRadius="12px"
                            p={{ base: "5px", md: "10px" }}
                        >
                            <Image src={"https://app.moverse.biz/media/prod/mvs-image-res-01/upload/mvs/res/0f691f28-9e8c-4450-a432-ae360e9bc89a.png"} />

                            <VStack mt="20px">
                                <ItemRowInfo title='TOKEN ID' value={item.tokenId} />
                            </VStack>

                            <VStack mt="20px">

                                <HStack>
                                    <Text
                                        fontSize="16px"
                                        lineHeight="140%"
                                        color={"#fff"}
                                        fontWeight={500}
                                        textAlign="right"
                                        wordBreak="break-all"
                                        noOfLines={1}
                                    >
                                        {`${DOMAIN_URL}/affiliate/${item.tokenId}`}
                                    </Text>

                                    <Tooltip
                                        label={value ? 'Copied' : 'Copy'}
                                        placement='top-start'
                                        bg={""}
                                        color={"#fff"}
                                    >
                                        <Icon
                                            as={CopyClipboar}
                                            cursor="pointer"
                                            ml="15px"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                copy(`${DOMAIN_URL}/affiliate/${item.tokenId}`);
                                            }}
                                        />
                                    </Tooltip>

                                    <Tooltip
                                        label={"Share"}
                                        placement='top-start'
                                        bg={""}
                                        color={"#fff"}
                                    >
                                        <Icon
                                            as={OpenLinkIcon}
                                            cursor="pointer"
                                            ml="15px"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                window.open(`${DOMAIN_URL}/affiliate/${item.tokenId}`)
                                            }}
                                        />
                                    </Tooltip>

                                </HStack>

                            </VStack>
                            <VStack mt="20px">
                                <AffTree nftId={item.tokenId} />

                                <ClaimView nftId={item.tokenId} />


                            </VStack>
                        </Box>
                    )
                })}

            </SimpleGrid>
        )
    }

    return (
        <Box>
            <Box h={"15vh"} />
            <VStack
                bg="transparent"
                w="full"
                // mt={{ base: "50px", md: "100px" }}
                // mb="90px"
                px={{
                    base: "10px",
                    md: "0px"
                }}
            >
                <Text
                    color="#D917E2"
                    fontWeight="700"
                    fontSize={{ base: "33px", md: "35px" }}
                    lineHeight={{ base: "40px", md: "44px" }}
                    textTransform="uppercase"
                >
                    Inventory
                </Text>
                {/* <Image src={TextVestingImage} /> */}
            </VStack>

            <Tabs variant='unstyled' pt={{ base: "0px", md: "45px" }} isFitted>
                <VStack>
                    <TabList>

                        <Tab
                            color="#fff"
                            _selected={{ bg: "primary" }}
                            fontWeight={500}
                            fontSize={{ base: "16px", md: "26px" }}
                            lineHeight='23px'
                            border="1px solid #FF00FF"
                            p={{ base: "5px", lg: "11px 51px" }}
                        >
                            NFT
                        </Tab>
                        <Tab
                            color="#fff"
                            _selected={{ bg: "primary" }}
                            fontWeight={500}
                            fontSize={{ base: "16px", md: "26px" }}
                            lineHeight='23px'
                            border="1px solid #FF00FF"
                            p={{ base: "5px", lg: "11px 51px" }}
                        >
                            BOX
                        </Tab>
                    </TabList>
                </VStack>

                <TabPanels pt={{ base: "15px", md: "45px" }}>

                    <TabPanel>
                        {renderTabNft()}
                    </TabPanel>
                    <TabPanel>
                        {renderTabBox()}
                    </TabPanel>
                </TabPanels>
            </Tabs>

            <Box h={{ base: "10vh", lg: "50vh" }} />

        </Box>
    )
}
