import { IStaking } from "~/dto";
export const resourceProjects: IStaking[] = [
    {
        month: "12",
        amountTokenStake: "666.667",
        totalLockValue: "60.000.030",
        apr: "150%",
        poolStakeAddress: "",
        packageIndex: 1
    },
    {
        month: "6",
        amountTokenStake: "2.000.000",
        totalLockValue: "200.000.000",
        apr: "50%",
        poolStakeAddress: "",
        packageIndex: 2
    },
    {
        month: "3",
        amountTokenStake: "8.000.000",
        totalLockValue: "480.000.000",
        apr: "50%",
        poolStakeAddress: "",
        packageIndex: 3
    }

]