import React from 'react'
import {
  ModalOverlay,
  Modal,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
  useClipboard,
  VStack,
} from '@chakra-ui/react'

import { useConnectWallet } from '~/hooks/@global'
interface IAccountModal {
  isOpen?: boolean
  onDismiss?: () => void
  login?: (connectorId: string) => void
  logout?: any
}

const AccountModal: React.FC<IAccountModal> = ({
  logout,
  isOpen,
  onDismiss: onClose,
}) => {
  const { account } = useConnectWallet();
  const { onCopy } = useClipboard(account)

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        maxWidth={{
          base: "fit-content",
          lg: "600px",
          xl: "600px",
          '2xl': "800px",
        }}
        w={{
          base: "fit-content",
          lg: "600px",
          xl: "600px",
          '2xl': "800px",
        }}
      >
        <ModalCloseButton color="#fff" />
        <ModalBody
          boxShadow="0px 0px 24px #4A0061"
          background="secondary"
        >
          <VStack p="50px">
            <Button
              minW="189px"
              color="#fff"
              fontSize="16px"
              borderRadius="8px"
              background="linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)"
              _focus={{
                backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
              }}
              _hover={{
                backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
              }}
              padding="7.59494px 30.3797px"
              w="244px"
              onClick={() => {
                if (logout) {
                  logout();
                  onClose();
                }
              }}
            >
              Logout
            </Button>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default AccountModal
