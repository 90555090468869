import { IAsk, INftOfCollection, IOrderBy } from '~/dto/market.dto'
import { ILogin } from '~/dto/moverse-app-api.dto';
import { rootApiService } from './@global'

const Endpoint = {
    login: 'api/business/moverse-app/login',
    syncWalletAddressByUser: 'api/business/moverse-app/sync-wallet-address-by-user',
    userDetail: 'api/business/moverse-app/user-detail',
}



export class MoverseAppService {

    async login(variables: {
        email: string
        password: string
    }) {
        return rootApiService.post<ILogin>(
            Endpoint.login,
            variables,
        )
    }


    async syncWalletAddressByUser(variables: {
        userRefId: string;
        walletAddress: string;
    }) {
        return rootApiService.post<ILogin>(
            Endpoint.syncWalletAddressByUser,
            variables,
        )
    }

    async userDetail(variables: {
        id: string;
    }) {
        return rootApiService.get<ILogin>(
            Endpoint.userDetail,
            variables,
        )
    }


}

export default new MoverseAppService()
