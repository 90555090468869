import React from 'react'
import {
  ModalOverlay,
  Modal,
  ModalContent,
  ModalCloseButton,
  HStack,
  Text,
  ModalHeader,
  Box,
  ModalBody,
  SimpleGrid,
  Icon,
} from '@chakra-ui/react'
import ChainConfig from '~/common/constants/ChainConfig'
import { EConnectorId } from '~/common/enums/chain.enum'

interface IConnectorWalletModal {
  isOpen?: boolean
  onDismiss?: () => void
  login?: any
}

const ConnectorWalletModal: React.FC<IConnectorWalletModal> = ({
  login,
  isOpen,
  onDismiss: onClose = () => {
    return null
  },
  ...rest
}) => {
  return (
    <Modal
      isOpen={isOpen || false}
      onClose={onClose}
      isCentered
      motionPreset='slideInBottom'
    >
      <ModalOverlay />
      <ModalContent
        maxWidth={{
          base: "fit-content",
          lg: "600px",
          xl: "600px",
          '2xl': "800px",
        }}
        w={{
          base: "fit-content",
          lg: "600px",
          xl: "600px",
          '2xl': "800px",
        }}
        zIndex="999"
        background="secondary"
        boxShadow="0px 0px 24px #4A0061"
      >
        <ModalHeader>
          <Text
            fontSize="20px"
            lineHeight="20px"
            color="white"
            fontWeight="extrabold"
            textAlign="center"
          >
            Connect to wallet
          </Text>
        </ModalHeader>
        <ModalCloseButton color="#fff" />
        <ModalBody>

          <SimpleGrid
            columns={{
              md: 2,
              lg: 2,
              xl: 2,
              '2xl': 2,
            }}
            spacing={2}
          >
            {ChainConfig.listWallet.map(({ title, icon, connectorId }) => (
              <Box
                cursor="pointer"
                key={title}
                onClick={() => {
                  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent)

                  // Since iOS does not support Trust Wallet we fall back to WalletConnect

                  if (title === 'Trust Wallet' && isIOS) {
                    login(EConnectorId.WalletConnect)
                  } else {
                    login(connectorId)
                  }
                  onClose();
                }}
                bg="white"
                role="group"
                borderRadius="20px"
              >
                <HStack
                  background="linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)"
                  _focus={{
                    backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
                  }}
                  _hover={{
                    backgroundColor: "linear-gradient(91.75deg, #FF1CF7 -0.86%, #2EB7CA 101.77%)",
                    color: "subText",
                    borderRadius: '8px',
                  }}
                  borderRadius="8px"
                  color="#fff"
                  alignItems="center"

                  px={{
                    base: '20px',
                    md: '25px',
                  }}
                  py="4px"
                  spacing={{
                    base: '5px',
                    md: '12px',
                  }}
                >
                  <Box>
                    <Icon
                      as={icon}
                      fontSize="64px"
                    />
                  </Box>
                  <Text
                    fontSize="16px"
                    lineHeight="19px"
                    fontWeight="bold"
                    pl="20px"
                  >
                    {title}
                  </Text>
                </HStack>
              </Box>
            ))}
          </SimpleGrid>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ConnectorWalletModal
