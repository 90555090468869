import { useWeb3React } from "@web3-react/core";
import { Contract, constants, BigNumber } from "ethers";
import { useCallback, useEffect, useState } from "react";
import { configEnv } from "~/@config"
import { formatAmountToken } from "~/@helpers/block-chain.helper";
import { formatNumberToSymbol } from "~/@helpers/format.helper";
import ERC20__factory from "~/common/abis/ERC20__factory";
import PoolStakingFixed__factory from "~/common/abis/PoolStakingFixed__factory";
import ChainConfig from "~/common/constants/ChainConfig";
import { useAlert, useConnectWallet } from "../@global";
import { usePoolFixed } from "./usePoolFixed";




const { POOL_STAKING_FIXED, MOVE } = configEnv().ADDRESS_CONFIG;


export const usePoolFixedItem = ({ packageIndex }) => {
    const toast = useAlert();
    const { library, account } = useConnectWallet();
    const [isLoading, setLoading] = useState(false);

    const [packageInfo, setPackageInfo] = useState({
        price: '0',
        totalValueLocked: '0',
        timeLock: 0,
        totalAllocation: 0,
        totalBought: 0,
    });

    const [myStaked, setMyStaked] = useState('0');

    const loadPackageInfo = useCallback(async () => {
        console.log(`=====loadPackageInfo=====`);
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const poolCt = new Contract(
                POOL_STAKING_FIXED,
                PoolStakingFixed__factory.abi,
                library
            )
            const { price,
                timeLock,
                totalAllocation,
                totalBought,
                totalValueLocked, } = await poolCt.viewPackageInfoByIndex(packageIndex);
            setPackageInfo({
                price: formatNumberToSymbol(Number(formatAmountToken(price)) || 0),
                totalValueLocked: formatNumberToSymbol(Number(formatAmountToken(totalValueLocked)) || 0),
                timeLock: (timeLock as BigNumber).toNumber(),
                totalAllocation: (totalAllocation as BigNumber).toNumber(),
                totalBought: (totalBought as BigNumber).toNumber(),

            })
        } catch (error) {

        }
    }, [account, library, packageIndex])

    const loadMyStaked = useCallback(async () => {
        console.log(`=====loadMyStaked=====`);
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const poolCt = new Contract(
                POOL_STAKING_FIXED,
                PoolStakingFixed__factory.abi,
                library
            );
            const amount = await poolCt.amountStakedOfUser(account, packageIndex)
            setMyStaked(formatNumberToSymbol(Number(formatAmountToken(amount)) || 0));
        } catch (error) {

        }
    }, [account, library, packageIndex])


    useEffect(() => {
        loadPackageInfo()
    }, [loadPackageInfo])

    useEffect(() => {
        loadMyStaked()
    }, [loadMyStaked])

    const stake = useCallback(async () => {
        setLoading(true);
        try {
            if (!account || !library) {
                throw new Error("Acount empty");
            }
            const signer = library.getSigner(account);
            const poolCt = new Contract(
                POOL_STAKING_FIXED,
                PoolStakingFixed__factory.abi,
                library
            )
            const { transactionHash } = await (await poolCt.connect(signer).stake(
                packageIndex
            )).wait();

            await Promise.all([
                loadMyStaked(),
                loadPackageInfo()
            ])
            toast.success(`${ChainConfig.getUrlScan()}/tx/${transactionHash}`);

        } catch (error) {
            toast.handleErrorBlockChain(error)
        }
        setLoading(false);
    }, [account, library, loadMyStaked, loadPackageInfo, packageIndex, toast]);




    return {
        isLoading,
        stake,
        ...packageInfo,
        percentStaked: packageInfo.totalBought * 100 / packageInfo.totalAllocation,
        myStaked
    }

}